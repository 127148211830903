import React, { useEffect } from "react";
import NavbarLayout from "../../layouts/NavbarLayout";
import career from "../../assets/careers.gif";
import empty from "../../assets/no-data-found.gif";
import Footer from "../../layouts/Footer";

const Careers = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bg-mainBg  h-[100vh] w-full">
        <NavbarLayout />

        <div className="max-w-screen-xl lg:mx-auto mx-4 pt-36 ">
          <div className="flex lg:flex-row flex-col ">
            <div className="lg:m-left m-auto lg:text-left text-center ">
              <span className="">
                <h1 className="text-secondary   md:text-[90px] text-[50px] font-semibold leading-none lg:w-[600px] w-full">
                  Build your career with
                </h1>
              </span>

              <div className="mt-5  ">
                <span className="font-semibold md:text-[70px] text-[32px] text-[#1B97D5]  bg-opacity-40 pb-4  px-3 rounded-md leading-normal">
                  Aeyron
                </span>
              </div>
            </div>

            <img src={career} className=" w-full" />
          </div>

          <div className="my-10 flex  justify-center">
            <img src={empty} className="mt-64" />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Careers;
