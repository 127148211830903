import React from "react";
import NavbarLayout from "../../layouts/NavbarLayout";
import {
  About,
  Client,
  Contact,
  Industry,
  Portfolio,
  Services,
  TechnologyStack,
} from "..";
import Footer from "../../layouts/Footer";
import { TrustedClient } from "../../type/enum";
import Slider from "../../components/pageSlider";
import Lottie from "react-lottie";
import rocker from "../../assets/animation/hero.json";
const home = () => {
  return (
    <>
      <div className="bg-mainBg  h-[100%] w-full">
        <NavbarLayout />

        <div id="#" className="max-w-screen-xl lg:mx-auto mx-4 pt-40 ">
          <div className=" flex flex-col items-center">
            <h1 className="text-secondary text-center w-full  md:text-[90px] text-[50px] font-semibold leading-none  w-full">
              Transforming Ideas Into
            </h1>

            <div className="md:w-[731px] w-full text-center h-[84] mt-6  ">
              {/* <span className="font-bold  bg-[#B9D1F8] bg-opacity-40 md:text-[63px] text-[32px] text-[#1B97D5] mt-4  pb-3 pt-[-5px] px-3 rounded-md leading-normal"> */}
              <span className="font-bold bg-opacity-40 md:text-[63px] text-[32px] text-[#1B97D5] mt-4  pb-3 pt-[-5px] px-3 rounded-md leading-normal">
                AI - Powered Realities
              </span>
            </div>

            <a
              href="/#services"
              className="rounded-md bg-btnBg hover:bg-primary text-primary text-center pt-3 hover:text-btnBg hover:shadow-lg text-[24px] py-1 px-5  mt-9 text-[#042739] w-[294px] h-[62px] text-[23]"
            >
              Our Services
            </a>

            <div className="md:w-[540px] w-[320px] md:h-[500px] h-[300px] ">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: rocker,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* about Us  */}
      <About />

      {/* services  */}
      <Services />

      {/* our industry  */}
      <Industry />

      {/* portfolio  */}
      <Portfolio />

      {/* client  */}
      <Client />

      {/* TechnologyStack  */}
      <TechnologyStack />

      <div className="w-full text-center">
        <div className="mb-6">
          <span className=" text-[24px] w-full ">
            Trusted by the 150+ companies
          </span>
        </div>

        <Slider cards={TrustedClient} />

        <div className="max-w-screen-xl mx-auto my-20 h-[400px] bg-[#F1F4F8] rounded-2xl ">
          <div className="w-full flex flex-col text-center items-center justify-center h-full">
            <h2 className="font-bold md:text-[72px] text-[40px] leading-none">
              Have some other ideas?
            </h2>
            <span className="font-medium md:text-[40px] text-[20px]  mt-6 leading-none">
              We have got you covered
            </span>
            <a href="https://calendly.com/khizarhussain" target="_blank">
              <button className="w-[354px] text-primary text-[20px] hover:bg-primary hover:text-btnBg hover:shadow-lg h-[60px] bg-btnBg rounded-full mt-12 ">
                Book for Free Consultation
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* footer  */}
      <Contact />
      {/* footer  */}
      <Footer />
    </>
  );
};

export default home;
