import React from 'react'
import Slider from '../../components/slider'
import { portfolios ,} from "../../type/enum"
const portfolio = () => {
  return (
    <section id='portfolio' className="bg-[#F5FDFF] py-[100px]">
    <div className="max-w-screen-xl lg:mx-auto  mx-4">

      <div className="">
        <h2 className="font-semibold text-[48px]  text-center">Case Studies</h2>
      </div>
     
            <Slider data={portfolios} is={"portfolio"}/>

    </div>
  </section>
  )
}

export default portfolio