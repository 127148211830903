import React from 'react'
import Card from '../../components/card'
import { industries} from "../../type/enum"

const industry = () => {
  return (
    <section id='industry' className='max-w-screen-xl lg:mx-auto mx-4 py-[100px]'>
       <h2 className='font-semibold text-[48px] text-center '>Our Expertise</h2>

       <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-rows-1 gap-2 mt-8'>

        {industries.map((item, index) => (
            <Card key={index} img={item.img} title={item.title} desc={item.desc}/>
        ))}

       </div>
    </section>
  )
}

export default industry