import React from "react";

const card = ({ img, title, desc }) => {
  return (
    <div className="shadow-md border border text-center items-center justify-center  h-[500px] rounded-2xl">
      <div className="w-full h-fit flex justify-center">
        <img src={img} className="w-[297px] h-[297px]  " />
      </div>

      <div className="px-2">
        <h2 className="font-semibold text-[20px] text-[#042638]">{title}</h2>

        <span className="text-[16px] text-[#042638]">{desc}</span>
      </div>
    </div>
  );
};

export default card;
