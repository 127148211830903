import React from "react";
import NavbarLayout from "../../layouts/NavbarLayout";
import Footer from "../../layouts/Footer";
import location from "../../assets/icon/location.png";
import phone from "../../assets/icon/phone.png";
import email from "../../assets/icon/email.png";
import time from "../../assets/icon/time.png";
import Map from "../../components/map";
import ContactForm from "../../components/contacatForm";

function Contact() {
  return (
    <section id="contactus" className="w-full py-[100px]">
      <NavbarLayout />
      <div className="max-w-screen-xl lg:mx-auto px-4 pt-8 mb-12 ">
        {/* <h1 className="text-3xl font-bold mb-8 text-center">Contact Us</h1> */}
        <div className="flex  lg:flex-row flex-col gap-8">
          {/* Contact Info */}

          <ContactForm />
          {/* Google Maps */}
          <div className="relative h-[534px] w-full rounded-lg mb-3 mt-1">
            <Map />
          </div>
        </div>

        <div className=" w-full grid md:grid-cols-2 grid-cols-1 gap-20 mt-20">
          <div className="flex h-fit flex-col bg-[#FAFAFA] p-6 rounded-md">
            <div className="flex flex-col">
              <img src={location} className="w-[48px] " />
              <h2 className="font-medium text-[29px] my-4">Address</h2>
            </div>
            <p className="leading-normal tracking-normal font-light text-[22px]">
              Office no. 5 and 6, 2nd Floor, Capital Plaza, Markaz, G-11 Markaz
              G 11 Markaz G-11, Islamabad, Islamabad Capital Territory 44000
            </p>
          </div>

          <div className="flex  h-fit flex-col bg-[#FAFAFA] p-6 rounded-md">
            <div className="flex flex-col">
              <img src={phone} className="w-[48px]" />
              <h2 className="font-medium text-[29px] my-4">Call Us</h2>
            </div>
            <a href="https://api.whatsapp.com/send?phone=923351900373&app=facebook&entry_point=page_cta&fbclid=IwAR0DDG93Zo5yqOv8kO6I5WZIKkUtsPDpjo_67rvlA85qCyD1yj2WFo74GLc">
              <p className="font-light text-[22px]">+92 335 5815205</p>
            </a>
          </div>

          <div className="flex  h-fit flex-col bg-[#FAFAFA] p-6 rounded-md">
            <div className="flex flex-col">
              <img src={email} className="w-[48px]" />
              <h2 className="font-medium text-[29px] my-4">Email us</h2>
            </div>
            <a href="mailto:khizar@aeyron.com">
              <p className="font-light text-[22px]">khizar@aeyron.com</p>
            </a>
          </div>

          <div className="flex  h-fit flex-col bg-[#FAFAFA] p-6 rounded-md">
            <div className="flex flex-col">
              <img src={time} className="w-[48px] h-[48px]" />
              <h2 className="font-medium text-[29px] my-4">Opening Hours</h2>
            </div>
            <div className="flex flex-col font-light text-[25px]">
              <p>Monday - Friday</p>
              <p>9:00 AM - 8:00 PM</p>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </section>
  );
}

export default Contact;
