import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import log from "../assets/logo/logo.png";

const NavbarLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (href) => {
    if (href === "/") {
      return location.pathname === "/" && !location.hash;
    }
    if (href.includes("#")) {
      const [basePath, hash] = href.split("#");
      return location.pathname === basePath && location.hash === `#${hash}`;
    }
    return location.pathname === href;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNavigation = (href, isHash) => {
    if (isHash) {
      const [basePath, hash] = href.split("#");
      if (basePath === location.pathname) {
        // Check if element exists, wait for it if necessary
        const scrollToElement = () => {
          const element = document.getElementById(hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          } else {
            console.warn(`Element with id '${hash}' not found.`);
          }
        };
        setTimeout(scrollToElement, 100); // Delay to ensure element is rendered
      } else {
        // Change path and scroll after navigation
        window.history.pushState({}, "", href);
        setTimeout(() => {
          const element = document.getElementById(hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      }
    } else {
      // Non-hash route
      scrollToTop();
    }
  };

  const links = [
    { label: "Home", href: "/", isExternal: false },
    { label: "About Us", href: "/#aboutUs", isExternal: true },
    { label: "Services", href: "/#services", isExternal: true },
    { label: "Industry", href: "/#industry", isExternal: true },
    { label: "Portfolio", href: "/#portfolio", isExternal: true },
    { label: "Testimonials", href: "/#testimonials", isExternal: true },
    { label: "Blogs", href: "/blogs", isExternal: false },
    { label: "Contact Us", href: "/#contactus", isExternal: true },
    { label: "Careers", href: "/careers", isExternal: false },
  ];
  return (
    <nav
      className={`bg-gradient-to-r from-left from-0% via-center via-37% to-right to-96% p-4  fixed top-0 z-10 w-full `}
    >
      <div className=" max-w-screen-xl mx-auto flex justify-between items-center">
        <div className="w-full flex justify-between">
          <div className="flex items-center">
            <div className="md:hidden">
              <button
                onClick={toggleNavbar}
                className="text-gray-400 focus:outline-none "
              >
                <svg
                  className="h-6 w-6 "
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
            <Link to="/" onClick={scrollToTop}>
              <img
                src={log}
                className="h-[35px] w-[120px] lg:h-full lg:w-[200px]"
              />
            </Link>
          </div>

          <div className="hidden w-full text-primary md:flex items-center justify-center ">
            {links.map((link, index) => (
              <div key={index} className="text-center">
                {link.isExternal ? (
                  <div className="w-[85px] text-center">
                    <Link
                      to={link.href}
                      className={`hover:font-bold  ${
                        isActive(link.href) ? "font-bold text-white" : ""
                      }`}
                      onClick={() =>
                        handleNavigation(link.href, link.href.includes("#"))
                      }
                    >
                      {link.label}
                    </Link>
                  </div>
                ) : (
                  <div className="w-[80px] text-center">
                    <Link
                      to={link.href}
                      className={`hover:font-bold  ${
                        isActive(link.href) ? "font-bold text-white" : ""
                      }`}
                      onClick={() =>
                        handleNavigation(link.href, link.href.includes("#"))
                      }
                    >
                      {link.label}
                    </Link>
                  </div>
                )}
              </div>
            ))}
          </div>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://calendly.com/khizarhussain"
            className="bg-primary text-[#1E907F] shadow-lg cursor-pointer rounded-full px-4 text-center w-[250px] h-[45px] font-bold text-[14px] flex items-center justify-center mt-2 hover:bg-[#1E907F] hover:text-primary"
          >
            Book An Appointment
          </a>
        </div>
      </div>

      {/* Sidebar and Overlay */}
      <div
        className={`fixed inset-0 z-20 transition-opacity duration-500 ease-in-out ${
          isOpen ? "bg-black bg-opacity-50" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setIsOpen(false)}
      ></div>

      <div
        ref={menuRef}
        className={`fixed inset-y-0 left-0 z-30 w-64  bg-gradient-to-b from-[#0C59E4] via-[#138CCD] to-[#00C9AB] text-white transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-500 ease-in-out`}
      >
        <div className="flex flex-col">
          {links.map((link, index) => (
            <div
              key={index}
              className={`text-left p-4 ${
                isActive(link.href) ? "bg-[#00C9AB]" : ""
              }`}
            >
              {link.isExternal ? (
                <div className="w-[85px] text-left">
                  <Link
                    to={link.href}
                    className={`hover:font-bold  ${
                      isActive(link.href) ? "font-bold text-white bg" : ""
                    }`}
                    onClick={() => {
                      handleNavigation(link.href, link.href.includes("#"));
                      setIsOpen(false);
                    }}
                  >
                    {link.label}
                  </Link>
                </div>
              ) : (
                <div className="w-[80px] text-left">
                  <Link
                    to={link.href}
                    className={`hover:font-bold  ${
                      isActive(link.href) ? "font-bold text-white" : ""
                    }`}
                    onClick={() =>
                      handleNavigation(link.href, link.href.includes("#"))
                    }
                  >
                    {link.label}
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default NavbarLayout;
