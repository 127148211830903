import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderComponent = ({ cards }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay interval to 5 seconds
    pauseOnHover: true, // Pause autoplay on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-wrapper">
      <Slider {...settings} className="md:mx-8 mx-4 gap-3">
        {cards?.map((card, index) => (
          <div key={index} className="card-container">
            <Card {...card} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderComponent;

export const Card = ({ img }) => {
  return (
    <div className="card bg-primary rounded-full border border-[#B7B7B7] mx-2 flex w-full max-w-[349px] h-[105px] justify-center items-center">
      <img
        src={img}
        className="w-[200px] h-auto object-contain p-4"
        alt="Card"
      />
    </div>
  );
};
