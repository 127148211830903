import React from 'react'
import aboutUs from   "../../assets/animation/about.json";
import Lottie from 'react-lottie';

const about = () => {
  return (
         <section id='aboutUs' className='max-w-screen-xl lg:mx-auto mx-4 py-[100px]'>
            <div className='flex md:flex-row flex-col '>
                    <div className='flex flex-col  w-full'>
                        <h2 className='font-semibold text-[48px]'>About Us</h2>
                        <p className='text-[30px] text-start py-2'>
                        We are a cutting-edge AI technology firm, specializing in comprehensive AI solutions that include UI/UX design, computer vision, and machine learning. Our team is comprised of top-tier AI Engineers, Computer Vision Engineers, Data Scientists, and Machine Learning Engineers. Not too large, not too small, we're perfectly balanced for innovation and efficiency, fully independent, and committed to delivering transformative, user-focused technologies on a global scale. 
                        </p>
                    </div>
                    <div className='w-full m-auto'>
                    <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: aboutUs,
              }}
              />
                    </div>
            </div>
         </section>
  )
}

export default about