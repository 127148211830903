import MartitimeVesseel from '../assets/images/portfolio/MartitimeVesseel.png'
import InventoryManagementSystem from '../assets/images/portfolio/InventoryManagementSystem.png'
import NeuraVue from '../assets/images/portfolio/NeuraVue.png'
import BikiaHealth from '../assets/images/portfolio/BikiaHealth.png'
import constructionSafety from '../assets/images/portfolio/constructionSafety.png'
import parkingManagement from '../assets/images/portfolio/parkingManagement.png'
import ActiveMeet from '../assets/images/portfolio/ActiveMeet.png'
import DroneBased from '../assets/images/portfolio/DroneBased.png'
import MaterialsProcurement from '../assets/images/portfolio/MaterialsProcurement.png'
import VisiviaFaceRecognition from '../assets/images/portfolio/VisiviaFaceRecognition.png'
import AiBasedSmartFridge from '../assets/images/portfolio/AiBasedSmartFridge.png'
import MedAide from '../assets/images/portfolio/MedAide.png'
import RetailsAnalytics from '../assets/images/portfolio/RetailsAnalytics.png'

export const industries = [
    {
        img:  "images/industry1.png",
        title: "AI & Computer Vision", 
        desc: "We develop state of the art solutions focusing retail, security, industry 4.0, quality assurance and much more"
    },
    {
        img:  "images/industry2.png",
        title: "Chatbots & Generative AI", 
        desc: "Our team has talented AI engineers that build industry-ready AI solutions for text, image generation and custom chatbots"
    },
    {
        img:  "images/industry3.png",
        title: "Web & Mobile Development", 
        desc: "We have in house development of amazing mobile and web applications for all platforms"
    },
    {
        img:  "images/industry4.png",
        title: "IOT & Cloud Infrastructure", 
        desc: "DevOps, MLOps and other IOT developments are also done readily at Aeyron"
    },
    
    

]

export const portfolios = [
    {
        img:  MartitimeVesseel,
        title: "Maritime Vessel Management System and ERP", 
        smallDesc : "Comprehensive Vessel Management System seamlessly integrated into existing ERP infrastructure. ",
        desc: "In collaboration with maritime industry leaders, we developed a comprehensive Vessel Management System seamlessly integrated into their ERP infrastructure. Our solution streamlined crew management, maintenance scheduling, inspections and drill management. and regulatory compliance, enhancing operational efficiency and maximizing vessel uptime. This project exemplifies our expertise in tailored ERP solutions for complex industries."
    },
    {
        img:  InventoryManagementSystem,
        title: "Inventory Management System for Company in Food and Beverage Industry", 
        smallDesc : "Inventory Management Solution",
        desc: "Custom SAAS material procurement, vendor management, recipe management and packaging solution for Dough with the Flow, one of the biggest doughnut kiosks in Islamabad. The aim was to automate the process and give insights to the company to increase their sales revenue."
    },
    {
        img:  NeuraVue,
        title: "Neuravue Territory Violation, Gesture Weapon Detection Using AI", 
        smallDesc : "Gesture Detection, Territory Violation, Weapon Detection",
        desc: "AI Apps & Integration Machine Learning , Neural Network , Artificial Intelligence , Software Development , React , NodeJS Framework , Computer Vision On-edge and cloud based AI surveillance solution built on PERN Stack (PostGreSQL, ExpressJS, ReactJS and NodeJS). Integrated with Firebase for real time notifications. The solution comprises on using AI to process real time streams for Weapon Detection, Zone Violation Identification, Person Fall Detection, Violence Detection and detection of a special emergency gesture. The resultant solution gave 30% increase in detecting security problems in public places, shopping stores and educational institutes."
    },
    {
        img:  BikiaHealth,
        title: "Bikia Health - Differential Diagnosis and Prescription Automation", 
        smallDesc : "Differential Diagnosis for Doctors, Automate a Prescription for Medicine.",
        desc: "AI Apps & Integration Computer Vision , Artificial Intelligence , Neural Network , Object Detection , Medical Imaging AI modules to improve the healthcare ecosystem. The Differential Diagnosis (DDX) Module focuses on differential diagnosis suggestions based on the symptoms provided by the user. The Medical Prescription (RX) Module is used to get dosage, recommendations and assistance about medicines. Medical Summary, Referral Letter and Dynamic Consent assist the doctors to swiftly produce in-house documents. Lesions and Classification module acts as a copilot to accurately identify Diabetic Retinopathy, Glaucoma, Cataract etc. A patient mobile app was also developed for this project."
    },
    {
        img:  constructionSafety,
        title: "Construction Safety using AI", 
        smallDesc : "Object Detection & Tracking , Machine Learning , Computer Vision , Artificial Intelligence",
        desc: "Object Detection & Tracking , Machine Learning , Computer Vision , Artificial Intelligence , Front-End Development , Front-End Development Framework Used AI to develop a construction safety detection algorithim. Ability to detect violations of - Helmet- Goggles - Shoes - Jackets etc."
    },
    {
        img:  parkingManagement,
        title: "AI Based Parking Management Solution", 
        smallDesc : "Cars which overstay their parking are charged appropriately. Can be used to analyze parking spaces, average duration etc. to devise relevant policy.",
        desc: "Artificial Intelligence , Deep Neural Network , Computer Vision , Front-End Development , Back-End Development , Object Detection & Tracking , React Bootstrap Custom parking solution to detect the overstaying cars in parking lots. Takes an image of the parking lot every 1 minute and processes it. For each violation, the dashboard captures an image of the car that is overstaying. Used Google Cloud Run and Container Registry for AI Webservices. The stack is PERN (PostGreSql, ExpressJS, ReactJS, Node) Google Pub-Sub was also used for realtime reporting of events and communications. The dashboard also gave information about the sites, cameras, region of interests and occupancy, median visit duration and average parking duration on different sites."
    },
    {
        img:  ActiveMeet,
        title: "ActiveMeet - On-Premise Meetings Assistant", 
        smallDesc : "On-Premise Meetings Assistant: Create Meeting Notes, Translation etc.",
        desc: "Large Language Model , Artificial Intelligence , Web Application , Machine Learning , Machine Learning Algorithm , Neural Network An on-premise solution for transcribing and analyzing meetings in realtime. It easily connects to your meeting on Microsoft Teams/Google Meet or Zoom.Able to do real time speech to text in multiple languages i.e English, Arabic and Spanish. Generate summaries, keypoints and chapters for each recorded meeting.The platform that we built also allows user to annotate the meetings, share it with others, modify the transcript and even export it into different output types."
    },
    {
        img:  DroneBased,
        title: "Drone Based Warehouse Management Systemt", 
        smallDesc : "AI + Drone Based Warehouse Inventory Detection. Counts inventory, which bin has how many items to auto-generate a stock.",
        desc: "Artificial Intelligence , Drone , Machine Learning , Object Detection & Tracking , Amazon Web Services , Computer Vision , Python , PyTorch Developed a smart drone capable of flying around the warehouse and scanning each bin location. Within each aisle, four distinct AI models perform tasks such as bin detection, inventory counting, rug type identification, OCR for bin location, and QR code recognition. The integrated web application enhances operational efficiency, catering specifically to a leading rug manufacturer in the United States."
    },
    {
        img:  MaterialsProcurement,
        title: "Materials Procurement SaaS", 
        smallDesc : "Inventory Management Solution",
        desc: "Front-End Development , Back-End Development , Inventory Management , Ecommerce Website Development Custom SaaS Material Procurement, Vendor Management, Recipe Management and Packaging Solution for one of the biggest doughnuts kiosk in Islamabad. The aim was to automate the process and give insights to the company to increase their sales revenue."
    },
    {
        img:  VisiviaFaceRecognition,
        title: "Visivia - Face Recognition Locks", 
        smallDesc : "Face-Recognition Locks",
        desc: "Artificial Intelligence , Computer Vision , Neural Network , Machine Learning , Object Detection & Tracking 3D face recognition based door locks completely made in-house by our team of engineers. PCBs were also custom designed to optimize speed and reduce latency.  Used 3D depth cameras for face spoof identification, combining it with a mobile and web application to create a smooth face recognition system.The web app had admin access to all devices and their roles, It was also able to revoke and manage access to these user groups. The recognition algorithm supported thousands of faces for rapid search without latency."
    },
    {
        img:  AiBasedSmartFridge,
        title: "AI Based Smart Fridge", 
        smallDesc : "Real time IOT based readings from sensors inside the fridge",
        desc: "Artificial Intelligence , TensorFlow , Deep Learning , Google Cloud Platform , Internet of Things , Machine Learning , Computer Vision , Arduino , PyTorch , Neural Network Developed AI based inventory management solution for a company in USA. (MamaGaia - Smart Fridges) • Used Yolov5 with Jetson nano + Pi to handle stream from 8 different cameras and scan the inventory in real time. • Android + Ios Apps, Real time inventory and Vendor + Admin Dashboards• Real time IOT based readings from sensors inside the fridge - MQTT"
    },
    {
        img:  MedAide,
        title: "MedAide - Medical Chatbot to Diagnose Patients", 
        smallDesc : "On-Premise Healthcare Chatbot: To Help Patients",
        desc: "MedAide-7B is an on premise AI based healtchare LLM that is trained on public forums containing healthcare and doctor-patient conversations. MedAide aims to act as chatbot for answering doctors and patients medical queries without relying on any 3rd party API/service. This makes it secure and reliable for keeping user's privacy especially for GDPR, HIPAA and other data privacy compliances."
    },
    {
        img:  RetailsAnalytics,
        title: "Retail Analytics using AI", 
        smallDesc : "Takes photos of retail racks/shelves to provide a count of items.",
        desc: "Artificial Intelligence , Neural Network , Computer Vision , Python , Arduino , Keras , Google Cloud Platform , PyTorch , TensorFlow , Raspberry Pi• Real time person identification and tracking • Real time occupancy detection in an office • Face mask detection, gender detection and emotions detection. • Face detection and recognition • Heatmaps and demo graphs • Used Yolov5 and custom annotations to achieve state of the art results. • Deployed on a live stream with IP camera."
    },
    
    

]
export const client = [
    {
        img:  "images/NHSP.jpg",
        name: "Afshan Ahmad", 
        companyName: "Neuravue",
        clientTitle: "CEO",
        location: "Toronto, Canada",
        desc: "Hands off the best team to work with on Upwork! Aeyron Technologies shines through all deliverables, goes above and beyond in getting the best possible solution and really care for their clients! They are all round the best team to work with!"
    },
    {
        img:  "images/NHSP.jpg",
        name: "Fabio Kunze", 
        companyName: "",
        clientTitle: "CEO",
        location: "Germany",
        desc: "Very good communication. What I liked about working with Aeyron Technologies is that they tried to understand the project, which led them to ask good questions that (if not asked) could have cost a lot of time and money in the future. They offered multiple ways to approach each problem and gives regular updates, even when I did not tell them to do so - very professional."
    },
    {
        img:  "images/NHSP.jpg",
        name: "Douglas Monsky", 
        companyName: "TECHFORGE AI LLC",
        clientTitle: "COO",
        location: "USA",
        desc: "The team at Aeyron Technologies are gentlemen and a pleasure to work with. Our progress was incredible and I look forward to collaboration in the future."
    },
    {
        img:  "images/NHSP.jpg",
        name: "Arthur Tutt", 
        companyName: "Arthur Tutt Consulting",
        clientTitle: "CEO",
        location: "New Zealand",
        desc: "The team at Aeyron Technologies is easy to talk to and very knowledgeable. They knows computer vision inside and out, and are able to present difficult topics in a clear and articulate way. Definitely would work with them again in the future."
    },
    {
        img:  "images/NHSP.jpg",
        name: "Tom Kinsley", 
        companyName: "Solform",
        clientTitle: "Founder",
        location: "Australia",
        desc: "Many thanks to Aeyron Technologies. Excellent communication and on time delivery of results. I appreciate the thinking outside of the box that they did to provide a bespoke integrated solution. The project was based around computer vision and video AI analysis. This was integrated into full stack development. Highly recommended"
    },
    {
        img:  "images/NHSP.jpg",
        name: "Doron Hubshush", 
        companyName: "DH Consulting",
        clientTitle: "CEO",
        location: "Israel",
        desc: "The team at Aeyron are true professionals. They went above and beyond the scope of the project to deliver a fantastic result. The team managed the entire process flawlessly from start to finish. I have nothing but warm words to say. We will most definitely work with Aeyron again, hopefully many more times. Highly recommended"
    },
    {
        img:  "images/NHSP.jpg",
        name: "Ron Porat", 
        companyName: "Kailash Consulting",
        clientTitle: "Owner and CEO",
        location: "Israel",
        desc: "The team at Aeyron are top-notch developers and data science professionals. They are dedicated to their work and clients and deliver high quality products, always there to assist and support. I highly recommend Aeyron Technologies"
    },
    {
        img:  "images/NHSP.jpg",
        name: "Sarah J. Lynch", 
        companyName: "SellWithSesame",
        clientTitle: "CEO",
        location: "Colarado, USA",
        desc: "We worked with Khizar & his team for over 10 months, with Khizar as the lead and main point-of-contact. During that time, they managed a huge scope of work, delivered high-quality milestones, and were enjoyable to work with. Khizar is an excellent communicator, an eager problem-solver, and a great engineer. I would easily recommend Khizar & his team to anyone in need of software and/or AI development."
    },
    {
        img:  "images/NHSP.jpg",
        name: "Simon Leininger", 
        companyName: "WakeSmart",
        clientTitle: "CEO",
        location: "USA",
        desc: "I have been working with Aeyron for the past 2 months and they have been great. I have learned a lot in the process and they have helped me through everything. I am very excited to see what the final product will look like for my project."
    },

    
    

]
export const technologyStacks = [
    {
        img:  "images/computerVision.png",
        name: "Computer Vision", 
     },
    {
        img:  "images/web.png",
        name: "Web Developement", 
    },
    {
        img:  "images/cloud.png",
        name: "Cloud Expertise", 
     },
    {
        img:  "images/uxUI.png",
        name: "UI/UX & Graphic Design", 
    },
    
     {
        img:  "images/database.png",
        name: "Database Expertise", 
     },
    {
        img:  "images/nlp.png",
        name: "NLP", 
    },
     {
        img:  "images/generativeAI.png",
        name: "Generative AI", 
     },
   
    
    

]
export const TrustedClient = [
    {
        img:  "images/client/lg1.png",
       
    },
    {
        img:  "images/client/lg2.png",
      
    },
    {
        img:  "images/client/lg3.png",
       
    },
    {
        img:  "images/client/lg4.png",
      
    },
    {
        img:  "images/client/lg5.png",
       
    },
    {
        img:  "images/client/lg6.png",
      
    },
    {
        img:  "images/client/lg7.png",
       
    },
    {
        img:  "images/client/lg8.png",
      
    },
    {
        img:  "images/client/lg9.png",
       
    },
    {
        img:  "images/client/lg10.png",
        
      
    },
    {
        img:  "images/client/lg11.png",
       
    },
    {
        img:  "images/client/lg12.png",
      
    },
    {
        img:  "images/client/lg13.png",
       
    },
    {
        img:  "images/client/lg14.png",
      
    },
    {
        img:  "images/client/lg15.png",
       
    },
    {
        img:  "images/client/lg16.png",
      
    },
    

]