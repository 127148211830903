import React from 'react'
import {
 Home,
 Careers,
 Blogs,
 Contact,
 ReadMore
} from "../pages"

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/casestudy/:title" element={<ReadMore />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Routing